<template>
  <!-- 公告列表 -->
  <div>
    <div class="course-details">
      <div class="course-details-title" @click="$router.go(-1)">
        公告
      </div>

      <div class="course-details-content">
        <div class="details-content-item" @click="toNoticedetails(item.id)" v-for="(item, index) in dataList"
          :key="index">
          <div class="details-content-item-left">
            <img src="@/assets/img/pre/icon-gg.png">
            <div class="item-title">
              {{ item.title }}
            </div>
          </div>
          <div class="details-content-item-time">{{ item.time }}</div>
        </div>
      </div>

      <div class="ye">
        <!--分页组件-->
        <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
          :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize"
          :total="page.totalPage" layout="prev, pager, next" style="text-align: center;">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
export default {
  name: "BulletinMore",
  data() {
    return {
      dataList: [
        // {
        //   id: 1,
        //   title: '公告内容公告标题公告内容公告标题',
        //   time: '2020-02-11'
        // },
      ],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 8,
        //总页数
        totalPage: 0,
      },
    }
  },
  mounted() {
    this.getNotice();
  },
  methods: {
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },
    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val;
      // console.log(val)
      this.getNotice();
    },
    // 获取公告数据
    getNotice() {
      // console.log(this.page.pageIndex)
      httpGet("/news-info/list",
        {
          typeId: 1,
          size: this.page.pageSize,
          current: this.page.pageIndex,
        },
        (res) => {
          this.dataList = res.data.records
          this.page.totalPage = res.data.total
          // console.log(this.dataList)
        },
        (err) => {
          // // console.log(err)
          throw err
        })
    },
    // 跳转详情页
    toNoticedetails(id) {
      // console.log(id)
      this.$router.push({
        name: "BulletinDetails",
        params: {
          noticeId: id
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.course-details {
  position: relative;
  padding: 0 3.38624%;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(3, 27, 78, 0.06);
  border-radius: 2px 2px 2px 2px;
  height: 49.5rem;

  .course-details-grade {
    width: 100%;
    height: 6rem;
    background: #F5F8FA;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 1.25rem;

    .line {
      width: 1px;
      height: 2rem;
      background: #D9D9D9;
    }

    .title {
      height: 1.5rem;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      line-height: 1.5rem;
    }

    .grade {
      height: 1.5rem;
      font-size: 1.5rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 1.5rem;
    }
  }

  .course-details-content {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    margin-top: 1.25rem;
    position: relative;

    .details-content-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 73px;
      //background-color: #d0d0d0;

      .details-content-item-left {
        display: flex;

        img {
          display: inline-block;
          width: 24px;
          height: 24px;
        }

        .item-title {
          width: 520px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 12px;
          text-align: left;
          //background-color: #42b983;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #3E454D;
        }

        .item-title:hover {
          color: #00a1d6;
        }
      }

      .details-content-item-time {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;
      }
    }

  }

  .ye {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  /* 当前状态颜色 */
  ::v-deep .el-progress-bar__outer {
    background: rgba(0, 0, 0, 0.23);
  }

  ::v-deep .el-progress-bar {
    height: 0.75rem;
  }

  ::v-deep .el-progress__text {
    display: inline-block;
    vertical-align: middle;
    height: 1.5rem;
    font-size: 0.8125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E454D;
    margin-left: 0.625rem;
    line-height: 1;
  }

  .course-details-title {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }

  .course-details-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }
}
</style>